import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../styles/styles.css"
import ScrollToTop from "react-scroll-to-top"
import loadable from "@loadable/component"

const CodingComponentArchived = loadable(() => import("../components/codingComponentArchived"))

const CodingArchived = ({ data }) => {

  return (

    <Layout>

      <SEO
        title="Coding Previous Version"
        description="The previous version of the Coding course that was archived in 2023 to learn and develop coding skills."
        image="/logo.png"
        pathname="/codingArchived"

      />
      <ScrollToTop smooth color="#78CCC7"/>

      <CodingComponentArchived/>
    </Layout>
  )
}
export default CodingArchived